export const formatDateDayMonthYear = (date) => {
  // convert date to a formatted string with structure DD-MMM-YYYY
  const formatDate = new Date(date).toLocaleDateString("en-AU", {
    day: "numeric",
    month: "short",
    year: "numeric",
  });

  return formatDate;
};

export const formatDateYMD = (date) => {
  const formattedDate = [
    date.getFullYear(),
    ("0" + (date.getMonth() + 1)).slice(-2),
    ("0" + date.getDate()).slice(-2),
  ].join("-");

  return formattedDate;
};

export const formatDateDDMMMYY = (date) => {
  const options = { year: "2-digit", month: "short", day: "2-digit" };
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(date);
  return formattedDate;
};

export function formatDate({ date, format }) {
  if (format === "dd-MMM-yy") {
    return formatDateDDMMMYY(date);
  }

  const formattedDate = formatDateYMD(date);
  return formattedDate;
}

export const addDaysToDate = (dateString, days) => {
  const date = new Date(dateString);
  date.setDate(date.getDate() + days);

  return date;
};

export function convertMinutesToHours(minutes) {
  const hours = Math.floor(minutes / 60);
  const minutesLeft = (minutes % 60).toFixed(2);
  const percentageOfHourLeft = parseFloat((minutesLeft / 60).toFixed(2));

  return {
    hours,
    minutes: minutesLeft,
    hoursWithMinutes: hours + percentageOfHourLeft,
  };
}

export function convertHoursToDays(hours) {
  const days = Math.floor(hours / 24);
  const hoursLeft = (hours % 24).toFixed(2);
  const percentageOfDayLeft = parseFloat((hoursLeft / 24).toFixed(1));

  return {
    days,
    hours: hoursLeft,
    daysWithHours: days + percentageOfDayLeft,
  };
}
