import { formatDateDayMonthYear } from "utils/helpers/dateTimeHelpers";
import LinkCard from "./LinkCard";
import { truncateText } from "utils/text";

export default function StoryLinkCard({
  story,
  metadata = true,
  tags = true,
  titleHeight,
  cardProps,
  cardData,
  containerStyles,
  maxTitleLength,
}) {
  // const publishedDate = formatDateDayMonthYear(story.publishedAt);
  const publishedDate = !!story?.date_created
    ? formatDateDayMonthYear(story?.date_created)
    : story?.publishedAt;
  const data1 = metadata
    ? `${publishedDate} | ${story?.author?.authorName}`
    : "";

  const tagData = tags ? [story?.story_category?.[0]?.category_name] : "";
  return (
    <LinkCard
      title={
        maxTitleLength > 0
          ? truncateText(story.title, maxTitleLength)
          : story.title
      }
      slug={`/stories/${story.slug}`}
      image={story?.search_engine_optimization?.metaMedia?.url}
      data1={metadata ? data1 : ""}
      readMore={metadata ? true : false}
      tags={tagData}
      titleHeight={titleHeight ? titleHeight : 2}
      cardData={cardData}
      containerStyles={containerStyles}
      {...cardProps}
    />
  );
}
